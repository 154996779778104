<template>
  <div id="app">
    <lemon-imui
        :width="lemonWidth"
        :height="lemonHeight + 'px'"
        simple
        :user="user"
        ref="IMUI"
        @pull-messages="handlePullMessages"
        @message-click="handleMessageClick"
        @send="messageSend"
    >
      <template #cover>
        <div class="cover">
          <span><b>大橙</b>同学</span>
        </div>
      </template>
    </lemon-imui>

    <el-dialog
        title="广告一下~"
        :visible.sync="$store.getters['systemConfig/getGGShow'] && isPhone"
        :close-on-press-escape="false"
        :show-close="false"
        width="80%"
        :before-close="handleClose">
      <iframe frameborder="no" style="pointer-events: none" src="https://file.charonv.com/GG.html" width="100%"
              height="100vh" id="ifra"/>
      <span slot="footer" class="dialog-footer">
  </span>
    </el-dialog>
  </div>
</template>

<script>
// const generateRandId = () => Math.random().toString(36).substr(-8);
import GptChatAsk from "../../request/expand/GptChat";
// import MarkdownItVue from 'markdown-it-vue'
// import 'markdown-it-vue/dist/markdown-it-vue.css'
import {isPhone} from "@/utils/validate";
import {fetchEventSource} from '@microsoft/fetch-event-source';

export default {
  name: "GptChat",
  components: {
    // MarkdownItVue
  },
  data() {
    return {
      ask: new GptChatAsk(),
      lemonWidth: "100%",
      lemonHeight: window.innerHeight - 60,
      quillShow: true,
      quillDialog: false,
      textDialog: 'Hello, **Vue-Meditor**!',
      user: {
        id: this.$store.state.user.userInfo.lUsrId,
        displayName: this.$store.state.user.userInfo.lUsrName,
        avatar: '/api/resources/user/' + this.$store.state.user.userInfo.lUsrId + '/' + this.$store.state.user.userInfo.lUsrIcon
      },
      continuousDialogue: false,
      oldMessageSize: 0,
      options: {
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener'
          }
        },
        katex: {
          throwOnError: false,
          errorColor: '#cc0000'
        },
        icons: 'font-awesome',
        githubToc: {
          tocFirstLevel: 2,
          tocLastLevel: 3,
          tocClassName: 'toc',
          anchorLinkSymbol: '',
          anchorLinkSpace: false,
          anchorClassName: 'anchor',
          anchorLinkSymbolClassName: 'octicon octicon-link'
        },
        mermaid: {
          theme: 'default'
        },
        image: {
          hAlign: 'left',
          viewer: true
        },
      },
      messageData: null,
      answering: false,
      askFlow: true,
      isPhone: false,
    };
  },
  created() {
    if (!this.$store.state.user.isLogin) {
      this.$message({
        showClose: true,
        message: '错了哦，未登录不可以进入闲聊喔!',
        type: 'error'
      });
      this.$router.push({path: '/login'})
      return;
    }
    this.sustainStateChange();
    window.onresize = () => {
      this.lemonHeight = window.innerHeight - 60;
      console.log(window.innerWidth)
    };
    this.isPhone = isPhone()
  },
  mounted() {
    const {IMUI} = this.$refs;
    IMUI.clearMessages('chatGpt');
    this.$refs.IMUI.initContacts([
      {
        id: "chatGpt",
        // displayName: "ChatGPT",
        avatar: "/charon.png",
        type: "single",
        index: "T",
        unread: 0,
      },
    ]);
    IMUI.initEditorTools([
      {
        name: "回答状态", isRight: false, title: '回答状态', click: () => {
        }, render: () => {
          return this.answering ? <i class="el-icon-loading"/> : <i class="el-icon-message"/>
        }
      },
      {
        name: "状态", isRight: false, title: '状态', click: () => {
          this.continuousDialogue = !this.continuousDialogue
        }, render: () => {
          return '连续对话: ' + (this.continuousDialogue ? '开启' : '关闭')
        }
      },
      {
        name: "回答类型", isRight: true, title: '回答类型', click: () => {
          this.askFlow = !this.askFlow
        }, render: () => {
          return '回答:  ' + (this.askFlow ? '流式' : '一次')
        }
      },
      // {
      //   name: "start", isRight: true, title: 'start', click: () => {
      //     this.continuousDialogue = !this.continuousDialogue
      //   }, render: () => {
      //     return '连续对话'
      //   }
      // },
      {
        name: "empty", isRight: true, title: '清空', click: () => {
          if (this.continuousDialogue) {
            this.delGptMessageSustain();
          }
          let dataList = IMUI.getCurrentMessages();
          let messageIds = [...dataList.map(item => item.id)];
          messageIds.forEach(id => {
            IMUI.removeMessage(id);
          });
          this.$storageHandler.removeLocalStorage("gptMessages");
          this.$message({
            showClose: true,
            message: '删除成功!',
            type: 'success'
          });
        }, render: () => {
          return '清空对话'
        }
      }])
    setTimeout(() => {
      IMUI.changeContact("chatGpt");
    }, 1000);
  },
  destroyed() {

  },
  // filters: {
  //   escapeHtml(value) {
  //     return value.replaceAll(/</g, '&lt;').replace(/>/g, '&gt;')
  //   }
  // },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.$store.commit("systemConfig/closeGgShow", false)
            done();
          })
          .catch(_ => {
          });
    },
    /**
     * 获取历史消息
     */
    getCacheMessage(pageSize) {
      let data = this.$storageHandler.getLocalStorage("gptMessages");
      if (data == null) {
        return [];
      }
      let totalLength = data.length
      return data.slice(totalLength - pageSize - 50 <= 0 ? 0 : totalLength - pageSize - 50
          , totalLength - pageSize)
    },
    /**
     * 把消息存本地缓存
     */
    setMessageLocalStorage(message) {
      let data = this.$storageHandler.getLocalStorage("gptMessages");
      if (data == null) {
        data = [];
      }
      data.push(message);
      this.$storageHandler.setLocalStorage("gptMessages", data);
    },
    /**
     * 更新当前是否有持续化对话
     */
    sustainStateChange() {
      this.ask.getSustainState().then(res => {
        if (res.code == 'success') {
          this.continuousDialogue = res.msg
        }
      })
    },
    /**
     * 清空持续对话消息列表
     */
    delGptMessageSustain() {
      this.ask.delGptMessageSustain().then(res => {
        if (res.code == 'success') {
          this.continuousDialogue = false;
        }
      })
    },
    /**
     * 注入消息
     * @param data
     */
    renderMessage(data) {
      const {IMUI} = this.$refs;
      IMUI.appendMessage(data, true);
      // IMUI.messageViewToBottom();
    },
    /**
     * 消息重发
     * @param e
     * @param key
     * @param message
     */
    handleMessageClick(e, key, message) {
      const {IMUI} = this.$refs;
      if (key == "status" && message.status === "failed") {
        IMUI.setEditorValue(message.content);
        // IMUI.updateMessage({
        //   id: message.id,
        //   toContactId: message.toContactId,
        //   status: "going",
        //   content: message.content,
        // });
        // setTimeout(() => {
        //   IMUI.updateMessage({
        //     id: message.id,
        //     toContactId: message.toContactId,
        //     status: "failed",
        //     content: message.content,
        //   });
        // }, 2000);
      }
      // else if (key == 'content' && this.quillShow) {
      //   this.quillDialog = true;
      //   console.log(message)
      //   this.textDialog = message.content
      // }
    },
    /**
     * 消息发送
     * @param message
     * @param next
     * @param file
     */
    handleSend(message, next, file) {
      if (this.answering) {
        return
      }
      this.answering = true;
      this.ask.postMessage(message, this.continuousDialogue ? 1 : 0).then(res => {
        if (res.code == 'success') {
          next({
            status: "succeed",
          });
          message.status = "succeed";
          this.setMessageLocalStorage(message);
          this.renderMessage(res.msg)
          this.setMessageLocalStorage(res.msg);
        }
        this.answering = false;
      }).catch(() => {
        next({
          status: "failed",
        });
        message.status = "failed";
        this.setMessageLocalStorage(message);
        this.answering = false;
      });
    },
    send1(message, next, file) {
      // Fetch the original image
      fetch(`/see/gptChar/completions/stream/${this.continuousDialogue ? 1 : 0}`, {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
        },
        body: JSON.stringify(message),
      })
          .then(response => {
            let data = response.json();
            console.log(data)
          })
          .then(data => console.log(data))
          .catch(error => console.error(error));

    },
    send(message, next, file) {
      const encode = new TextDecoder("utf-8");
      let this_ = this;
      const {IMUI} = this.$refs;
      // Fetch the original image
      fetch(`/see/gptChar/completions/stream/${this.continuousDialogue ? 1 : 0}`, {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
        },
        body: JSON.stringify(message),
      })
          // Retrieve its body as ReadableStream
          .then((response) => {
            const reader = response.body.getReader();
            return new ReadableStream({
              start(controller) {
                return pump();

                function pump() {
                  return reader.read().then(({done, value}) => {
                    const text = encode.decode(value);
                    // const uint8Array = new Uint8Array(value);
                    // 将 Uint8Array 对象转换为字符串
                    // const jsonString = new TextDecoder().decode(uint8Array);
                    console.log(text)
                    let data = JSON.parse(JSON.parse(text.substring(5, text.length)))
                    // let data = JSON.parse(text);
                    this_.answering = true
                    if (data.operateType === "create") {
                      next({
                        status: "succeed",
                      });
                      this_.messageData = data.message;
                      this_.renderMessage(data.message)
                      this_.setMessageLocalStorage(message);
                    } else if (data.operateType === "error") {
                      next({
                        status: "failed",
                      });
                      this_.$message.error(data.message);
                      controller.close();
                    } else {
                      this_.messageData.content += data.message
                      IMUI.updateMessage(this_.messageData);
                      IMUI.messageViewToBottom();
                    }
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                      console.log("关闭")
                      this_.setMessageLocalStorage(this_.messageData);
                      this_.answering = false
                      controller.close();
                      return;
                    }
                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value);
                    return pump();
                  });
                }
              }
            })
          })
          .catch((err) => {
            this_.answering = false;
            next({
              status: "failed",
            });
          });
    }
    ,

    messageSend(message, next, file) {
      if (!this.askFlow) {
        this.handleSend(message, next, file);
        return;
      }

      if (this.answering) {
        next({
          status: "failed",
        });
        return false
      }
      const abortController = new AbortController();
      const signal = abortController.signal;
      let this_ = this;
      const {IMUI} = this.$refs;
      let eventSource = fetchEventSource(`/see/gptChar/completions/stream/${this.continuousDialogue ? 1 : 0}`, {
        method: 'POST',
        signal: signal,  // 传入signal
        headers: {
          "Content-Type": 'application/json',
          "Accept": "text/event-stream",
        },
        body: JSON.stringify(message),
        onmessage(event) {
          let data = JSON.parse(JSON.parse(event.data));
          // console.log(data)
          if (data.end) {
            if (data.operateType === "error") {
              next({
                status: "failed",
              });
              this_.$message.error(data.message);
            } else {
              this_.setMessageLocalStorage(this_.messageData);
            }
            abortController.abort();
            this_.answering = false
          } else {
            this_.answering = true
            if (data.operateType === "create") {
              next({
                status: "succeed",
              });
              this_.messageData = data.message;
              this_.renderMessage(data.message)
              this_.setMessageLocalStorage(message);
            } else {
              this_.messageData.content += data.message
              IMUI.updateMessage(this_.messageData);
              IMUI.messageViewToBottom();
            }
          }
        },
        onerror(err) {
          abortController.abort();
          this_.answering = false;
          next({
            status: "failed",
          });
          throw err;
        }
      })
    },
    /**
     * 拉取最新消息
     * @param contact
     * @param next
     */
    handlePullMessages(contact, next) {
      let data = this.getCacheMessage(this.oldMessageSize);
      this.oldMessageSize = +data.length
      next(data, data.length < 50 ? true : false);
      // this.ask.getGptMessageList({"pageSize": this.oldMessageSize}).then(val => {
      //   this.oldMessageSize = +val.length
      //   // true 表示到末尾
      //   next(val, val.length < 50 ? true : false);
      // })
    },
    /**
     * 下拉到底部
     */
    messageViewToBottom() {
      const {IMUI} = this.$refs;
      IMUI.messageViewToBottom();
    },
    /**
     * 更新消息
     * @param Message 消息体
     */
    updateMessage(Message) {
      const {IMUI} = this.$refs;
      IMUI.updateMessage(Message)
    }
  },
};
</script>

<style>
.cover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #ddd;
}

.lemon-editor {
  text-align: left;
  height: 150px;
}

.lemon-editor__inner {
  border: solid;
  border-bottom: none;
}

.lemon-message__content {
  text-align: left;
  white-space: normal !important;
}

/*.markdown-preview p {*/
/*  !*white-space: pre-line; !* 设置 \n 解析为实际换行 *!*!*/
/*}*/

/*.el-dialog__body {*/
/*  padding: 0px;*/
/*}*/

.lemon-container__title {
  text-align: center;
}
</style>